export const stringsEN = {
  schema: {
    firstName: {
      invalid: 'Please enter a valid name.',
      required: 'First name is required.',
      min: 'First name must be at least 2 characters.',
      max: 'First name can not be longer than 55 characters.',
    },
    lastName: {
      invalid: 'Please enter a valid name.',
      required: 'Last name is required.',
      min: 'Last name must be at least 2 characters.',
      max: 'Last name can not be longer than 55 characters.',
    },
    email: {
      required: 'Email address is required.',
      invalid: 'Invalid email address.',
      inUse: 'Email address is already in use.',
    },
    country: {
      required: 'Country is required.',
    },
    language: {
      required: 'Language is required.',
    },
    title: {
      required: 'Title is required.',
    },
    eventImage: {
      required: 'Event image is required.',
    },
    zoomLink: {
      invalid: 'Invalid link.',
      required: 'Zoom link is required.',
    },
    phone: {
      required: 'Phone number is required.',
      invalid: 'Invalid phone number',
    },
    doterraId: {
      required: 'doTERRA ID is required.',
      invalid: 'Invalid doTERRA ID.',
    },
    password: {
      required: 'Password is required.',
      short: 'Password must be at least 8 characters.',
      invalid: 'Password requirements not met.',
    },
    generic: 'This field is required.',
  },
  // generics
  generic: {
    cancel: 'Cancel',
    forms: {
      firstName: 'First name',
      lastName: 'Last name',
      language: 'Language',
      phone: 'Phone number',
      country: 'Country',
      doterraId: 'doTERRA ID',
      email: 'Email address',
      password: 'Password',
      typeHere: 'Type here',
      search: 'Search',
    },
  },
  // avatar dropdown header
  hello: 'Hello',
  homepage: 'Visit homepage',
  myAccount: 'My Account',
  userManagement: 'User management',
  contact: 'Contact',
  logout: 'Logout',
  help: 'Help',
  // navigation
  products: 'Products',
  protocols: 'Protocols',
  resources: 'Resources',
  bookings: 'Bookings',
  eduCare: 'eduCare',
  consultation: 'Consultations',
  events: 'Events',
  search: 'Search',
  about: 'About',
  whyJoin: 'Why join?',
  faq: 'FAQ',
  liveEvent: 'Live Event',
  login: 'Login',
  changeLanguage: 'Change language ',
  // login page
  loginTitle: 'Members Login',
  loginSubtitle: 'A world of Magic and Essential Oils.',
  typeEmail: 'Type in your email',
  typePassword: 'Type in your password',
  forgotPassword: 'Forgot password?',
  registerLink: "Don't have an account?",
  buttonLogin: 'Login',
  // my account page
  myAccountPage: {
    removeAvatar: 'Remove image',
    country: 'Country of residence',
    language: 'Select language',
    doterraId: 'User doTERRA ID',
    emailVerified: 'Email verified',
    changePassword: 'Change password',
    update: 'Update information',
    deleteAccount: 'Delete account',
    deletedAccount: 'Your account has been successfully deleted.',
    deleteModal: {
      topText:
        'You are about to completely delete your account and all your information.',
      bottomText: {
        firstPart: 'Are you sure you want to',
        redText: 'permanently delete',
        secondPart: 'your account?',
      },
    },
  },
  //userManagement page
  usersManagement: {
    addUser: '+ Add User',
    all: 'All',
    serverError: 'Server error',
    genericError: 'Something went wrong. Try refreshing the page.',
    showing: {
      pre: 'Showing',
      user: 'user',
      plural: 'users',
      none: 'No users',
    },
    pagination: {
      show: 'Show',
      page: 'Page',
      of: 'of',
    },
    table: {
      name: 'Full name',
      country: 'Country',
      submissionDate: 'Form submission date',
      assignedEnroller: 'Assigned enroller',
      doTERRAID: 'doTERRA ID',
      accountType: 'Account type',
      status: 'Status',
    },
  },

  userManagementModal: {
    addUser: 'You can only add users ',
    website: 'Enrolled in Wonderland Essentials.',
    enrolledStatus: 'Enrolled',
    content: 'Has a doTERRA account under Wonderland Essentials.',
    doterraId: 'doTERRA ID',
    enroller: 'Enroller',
    account: 'Account type',
    firstName: 'First name',
    lastName: 'Last name',
    emailAddress: 'Email Address',
    country: 'Country',
    phone: 'Phone number',
    createAccount: 'Create New User Account',
    editAccount: 'Edit User Account',
    deleteAccount: 'Delete User Account',
    addedUser: 'Successfully added new user: ',
    updatedUser: 'Successfully modified user details for ',
    removedUser: 'Removed user: ',
    confirmedUser: 'Successfully confirmed user',
    addButton: 'Create New User',
    updateButton: 'Update information',
    cancelButton: 'Cancel',
    statusLabel: 'Account Status',
    accountType: {
      info: 'What are account types?',
      admin: {
        type: 'Admin',
        content: ' sees all protocols, events and has access to User Database.',
      },
      consumer: {
        type: 'Customer',
        content: ' sees selected protocols and events.',
      },
    },
    accountStatus: {
      title: 'What is Account Status?',
      notContacted: {
        tag: 'Not Contacted',
        content: 'No doTERRA account and is waiting to be contacted.',
      },
      pending: {
        tag: 'Pending',
        content: 'Contacted and doesn’t have a doTERRA account yet.',
      },
      enrolled: {
        tag: 'Enrolled',
        content: 'Has a doTERRA account under Wonderland Essentials.',
      },
    },
    phoneNumber: 'Phone number',
    deletedAccount: 'Your account has been successfully deleted.',
    deleteModal: {
      confirm: 'Confirm Delete',
      topText:
        'You are about to completely delete this user and all information. This action cannot be undone.',
      bottomText: {
        firstPart: 'Are you sure you want to ',
        redText: 'permanently delete',
        secondPart: ' this user?',
      },
      deleteButton: 'Yes, Delete it',
      cancelButton: 'Cancel',
    },
    errors: {
      createUser: 'Could not create user.',
      confirmUser: 'Could not confirm user.',
      email: 'This email address is taken.',
      updateUser: 'Something went wrong while updating user details.',
      doterraId: 'doTERRA ID is in use by another account.',
    },
  },
  // initial password setup page
  initialPasswordPage: {
    title: 'Welcome to Wonderland Essentials',
    subtitle:
      'We’re very thrilled you decided to join Wonderland Essentials. You will soon find out as well what an excelent decision you’ve made!',
    text: 'But first, let’s create your own password!',
    yourPassword: 'Your Password',
    confirmPassword: 'Confirm Your Password',
    update: 'UPDATE PASSWORD',
    terms: {
      accept: 'I accept the ',
      terms: 'terms and conditions',
    },
    required: 'You must accept the terms and conditions.',
  },
  // change password page
  chooseNewPasswordPage: {
    title: 'Choose new password',
    passwordRequirements:
      'Passwords must be at least 8 characters long and it must contain at least 1 digit, 1 uppercase letter, 1 lowercase letter and 1 special character.',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    save: 'SAVE NEW PASSWORD',
    confirm: {
      title: 'Password changed successfully',
      loginBTN: 'LOGIN',
    },
  },
  loginPage: {
    emailLabel: 'Email address',
    emailInput: 'Your email address',
    passwordLabel: 'Password',
    passwordInput: 'Your password',
    forgotPassword: 'Forgot password?',
    text: 'Become a member',
  },
  // set register page
  registerPage: {
    title: 'Leave us your details and we will get in touch',
    subtitle: 'Why can’t I simply create an account? ',
    text: "Because we believe in fair play. We also believe that to receive respect, we first need to offer it. Therefore to accommodate doTERRA's business model and other doTERRA's Advocates, Wonderland Essentials only accepts members that do not have a doTERRA account.",
    information: 'How does this work? ',
    textInformation:
      'Someone will contact you by phone within 24 hours and help you with answers, and initial setup.',
    firstName: 'First Name',
    lastName: 'Last Name',
    yourEmail: 'Your Email',
    countryOfResidence: 'Country of residence',
    phoneNumber: 'Phone number',
    doTerraAccount: "I don't have a doTERRA account",
    termsAndConditions: 'I accept the Terms and Conditions',
    registration: 'Apply for Registration',
    contact: '*For any other questions please write to us at ',
    website: 'support@wonderlandessentials.com',
    validations: {
      doterraAccount: 'You can not create multiple doTERRA account.',
      termsAndConditions: 'You must aggree to the terms & conditions.',
    },
  },
  //
  // set register page
  formSentConfirmationPage: {
    title: "We've sent your application",
    subtitle:
      'Thank you so much for being so interested in joining Wonderland Essentials.',
    text: "We will try and contact you within 24h by phone. If for some reason we won't, please send an email to ",
    website: 'support@wonderlandessentials.com',
    btnText: 'BACK TO HOMEPAGE',
  },
  // change password page
  changePassword: 'Change Password',
  passwordRequirements:
    'Passwords must be at least 8 characters long and it must contain at least 1 digit, 1 uppercase letter, 1 lowercase letter and 1 special character.',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  confirmPasswordChange: 'CONFIRM PASSWORD CHANGE',
  // form validation messages
  passwordsNoMatch: 'Passwords do not match match!',
  // error messages
  profileUpdateFail: `There was a problem updating account details. Try again later.`,
  // success messages
  profileUpdated: `Profile updated successfully.`,
  // forgot password page
  forgotPasswordPage: {
    text: "Enter the email associated with your account, and we'll send you a link to reset your password.",
    emailLabel: 'Email address',
    emailInput: 'Your email address',
    buttons: {
      continue: 'Continuare',
      login: 'Reset password',
      cancel: 'Cancel',
    },
    linkSent:
      "We've sent an email to the provided email address with further instructions.",
    backToHomepage: 'Back to homepage',
    emailNotFound: 'User not found',
    serverError: 'Something went wrong. Try again later.',
  },

  // products
  productsPage: {
    pageTitle: 'Products',
    addProduct: '+ Add product',
    showing: {
      pre: 'Showing',
      post: 'Product',
      plural: 'Products',
      none: 'No products',
    },
    categories: {
      singleOils: 'Single oils',
      blends: 'Blends',
      supplements: 'Supplements',
      personalCare: 'Personal Care',
      cleaning: 'Cleaning',
      oilKits: 'Kits',
      diffusers: 'Diffusers',
      accesories: 'Accesories',
    },
    productAdded: 'Products successfully added.',
    productNotSaved: 'Could not save product.',
    addProductTitle: 'Create New Product',
    addProductButton: 'Create product',
    selectProductLanguage: 'Select product language',
    applicationMethod: 'Application Method',
    selectLanguage: 'Product language',
    a: 'A - Aromatically',
    t: 'T - Topically',
    i: 'I - Internally',
    h: 'H - Household',
    skinSensitivity: 'Skin Sensitivity',
    n: 'N - Neat',
    s: 'S - Sensitive',
    d: 'D - Dilute',
    title: 'Product title',
    altName: 'Alternative Name',
    category: 'Product category',
    extractionMethod: 'Extraction method',
    description: 'Description',
    ingredients: 'Ingredients',
    benefits: 'Benefits',
    cautions: 'Cautions',
    translatedBy: 'Credits',
    imageRequired: 'Please select a product image.',
    videoUrl: 'Youtube embed URL',
  },
  // single product page
  productPage: {
    failedToFetch: 'Could not fetch product data.',
    breadcrumbs: {
      all: 'All Products',
    },
    selectProductLanguage: 'Select product language',
    faledToFetchSimilar: 'Could not get similar products.',
    deletedProduct: 'Product deleted successfully.',
    imageRequired: 'Please select a product image.',
    updated: 'Product updated successfully.',
    duplicate: 'A product with that name already exists.',
    failedUpdate: 'Could not update product details.',
    failedDelete: 'Could not delete product.',
    editProduct: 'Edit product',
    addProductTitle: 'Create New Product',
    updateProduct: 'Update product',
    deleteProduct: 'Delete product',
    initials: {
      a: 'A',
      t: 'T',
      i: 'I',
      n: 'N',
      s: 'S',
      d: 'D',
      h: 'H',
    },
    title: 'Product title',
    altName: 'Alternative Name',
    category: 'Product category',
    extractionMethod: 'Extraction method',
    description: 'Description',
    ingredients: 'Ingredients',
    benefits: 'Benefits',
    cautions: 'Cautions',
    similarTitle: 'Discover similar products',
    noSimilarProducts: 'No similar products',
    extraction: {
      none: 'None',
      notApplicable: 'Not Applicable',
      steamDistillationOfFlowers: 'Steam distillation of flowers',
      steamDistillationOfLeaves: 'Steam distillation of leaves',
      steamDistillationOfFruit: 'Steam distillation of fruit',
      steamDistillationOfRoots: 'Steam distillation of roots',
      steamDistillationOfRhizomes: 'Steam distillation of rhizomes',
      steamDistillationOfWood: 'Steam distillation of wood',
      steamDistillationOfResin: 'Steam distillation of resin',
      steamDistillationOfSap: 'Steam distillation of sap',
      steamDistillationOfSeeds: 'Steam distillation of seeds',
      steamDistillationOfBark: 'Steam distillation of bark',
      steamDistillationOfLeavesAndFlowers:
        'Steam distillation of leaves and flowers',
      steamDistillationOfNeedlesAndTwigs:
        'Steam distillation of needles and twigs',
      steamDistillationOfFruitsLeavesAndSeeds:
        'Steam distillation of fruits, leaves and seeds',
      steamDistillationOfLeavesAndYoungBranches:
        'Steam distillation of leaves and young branches',
      steamDistillationOfLeavesAndPlantTips:
        'Steam distillation of leaves and plant tips',
      steamDistillationOfLeavesAndStems:
        'Steam distillation of leaves and stems',
      steamDistillationOfTheFloweringPartOfThePlant:
        'Steam distillation of the flowering part of the plant',
      steamDistillationOfFlowerBudsStemsAndLeaves:
        'Steam distillation of flower buds, stems and leaves',
      steamDistillationOfLeavesSeedsSapAndBark:
        'Steam distillation of leaves, seeds, sap and bark',
      steamDistillationOfBudsAndStems: 'Steam distillation of buds and stems',
      steamDistillationOfFlowersBudsAndLeaves:
        'Steam distillation of flowers, buds and leaves',
      coldPressingOfFruitPeel: 'Cold pressing of fruit peel',
      hydroDistillationFromRoots: 'Hydro Distillation from roots',
      absoluteFromFlowers: 'Absolute from flowers',
      absoluteFromBeansOrPods: 'Absolute from beans/pods',
    },
    deleteModal: {
      title: 'Confirm delete product',
      confirm: 'Yes, delete it',
      body: {
        top: 'You are about to completely delete this product and it’s information.',
        text: 'The product will not be visible anymore. This action cannot be undone.',
        areYouSure: 'Are you sure you want to',
        deleteHighlight: 'permanently delete',
        end: 'this product?',
      },
    },
    confirmModal: {
      title: 'Confirm modifications',
      confirm: 'I understand',
      body: {
        text: 'Updating an active product might have an impact on some users.',
        secondText: 'Are you sure you want to do this?',
      },
    },
  },
  // my landing page
  landingPage: {
    about: 'About',
    whyJoin: 'Why Join',
    faq: 'FAQ',
    liveEvent: 'Live Event',
    register: 'Register',
    login: 'Login',
    changeLanguage: 'Change language',
    title: 'A World of Magic and doTERRA Essentials Oils',
    registration: 'Apply for Registration',
    subtitle: 'About Wonderland Essentials',
    testimonialOne:
      '“I stand on the sacrifices of a million women before me thinking what can I do to make this mountain taller so the women after me can see farther - legacy” Rupi Kaur',
    content:
      "I'm Alice Vacian, spiritual psychotherapist, emotional aromatherapist, a human with love for people, settled in Wonderland. I'm home. I'm here to help you get home, too. Let's all return to the essence.",
    author: 'Alice Vacian',
    teamTitle: 'ME=WE=Wonderland Essential',
    teamContent:
      'Our story is a story about when ME becomes WE, Wonderland Essentials. We feel blessed to create and expand this happy place we call home with wonderful people we call soul family. Meet the Wonderland Essentials family.',
    benefits: {
      title: 'Benefits of being enrolled in Wonderland Essentials',
      web: {
        title: 'Exclusive Web Application Access',
        content:
          'You and only you have access to the web application. (t)here you’ll find based on specialists’ expertise information to help your physical and emotional wellbeing, along with protocols, recommendations, private events, doTERRA oils information.',
      },
      oilProtocols: {
        title: 'Monthly Updated Oil Protocols',
        content:
          'You and only you have round-the-clock access to basic conditions and detailed recommendations. No matter what time it is, especially if it’s an emergency, you’ll access both physical and emotional administration best practices (Pst!, we’re among the few that offer such a thing). Oil Wiki in your pocket, basically. ',
      },

      events: {
        title: 'Weekly and Monthly Exclusive Events',
        startsIn: 'Starts in ',
        years: ' years',
        months: ' months',
        day: ' day',
        days: ' days',
        hours: ' hours',
        minutes: ' minutes',
        eventIn: '* This event is in english language',
        content:
          'You and only you have access to sets of one to three hours presentations and Zoom meetings. You’ll be able to attend oil camps - one-week-long events where we approach themes like self-love, Q&As, personalised recommendations - and learn how to incorporate essential oils into your daily routine.',
      },
      support: {
        title: 'Complete and Detailed Oils Information',
        content:
          'You and only you have access to oils full description, how they are made, benefits, applying methods, ingredients, and cautions. Also to oil information gathered from reliable sources, specialised books, curated by us. We assure you this is the kind of information that you don’t find on Google. ',
      },
    },

    notConvinced: {
      title: 'Still not convinced?',
      content: 'Apply for registration and talk with our consultants.',
      boldContent: 'It’s oily smooth.',
      button: 'Apply for Registration',
    },

    askedQuestions: {
      title: 'Frequently Asked Questions',
      text: 'Didn’t find the answer to your question? Please write to us at',
      website: ' support@wonderlandessentials.com',
    },

    clientsOpinion: {
      title: 'What others are saying',
      content:
        'Risus ultricies tristique nulla aliquet.Est placerat in egestas erat imperdiet sed euismod nisirisus commodo viverra maecenas accumsan lacus vel facilisis.',
      author: 'Raquel Velasquez',
    },
    consultations: {
      title: 'Consultations',
      content:
        "You have arrived in a special place. You can make an appointment for a one-on-one meeting with Alice, a 15-minute consultation in which you will receive a personalized recommendation to help you get through the challenges of this moment. There are two sections, for Romanian speakers and English speakers. Read the instructions carefully before making an appointment. Come with an open heart! If you can't find free space in your calendar and you have an emergency, I invite you to write an email to: ",
    },

    events: {
      title: 'Essential Oils Fundamentals',
      content:
        'Orci dapibus ultrices in iaculis nunc sed augue lacus. Porta non pulvinar neque laoreet suspendisse. Eu nisl nunc mi ipsum faucibus. Diam maecenas ultricies mi eget mauris pharetra et.',
      button: 'Add to Your Calendar',
    },
    footer: {
      text: 'Welcome home. Welcome to Wonderland Essentials. A world full of doTERRA essentials oils and magic - the magic of becoming. ',
      doTERRAWebsite: 'Official doTERRA website',
      cookie: 'Cookie Policy',
      privacy: 'Privacy Policy',
      terms: 'Terms and Conditions',
      poweredBy: 'Powered by',
      content: 'Wonderland Essentials. All rights reserved',
      disclaimer: 'Disclaimer',
    },
  },
  // events
  eventsPage: {
    pageTitle: 'Events',
    addEvent: '+ Create New Event',
    showing: {
      pre: 'Showing',
      post: 'Event',
      plural: 's',
      none: 'No events',
    },
    eventAdded: 'Events successfully added.',
    eventNotSaved: 'Could not save event.',
    addEventTitle: 'Add event',
    editEventTitle: 'Edit event',
    getEventsError: 'Unable to get events.',
    noEvents: 'No events.',
    thisWeek: 'Upcoming this week',
    thisMonth: 'Upcoming this month',
    nextMonth: 'Upcoming next month',
    updateEvent: 'Update event',
    selectEventLanguage: 'Select event language',
    selectLanguage: 'Event language',
    title: 'Title',
    altName: 'Alternative Name',
    category: 'Category',
    description: 'Description',
    translatedBy: 'Translated by',
    imageRequired: 'Please select an event image.',
    aboutPage:
      'On this page, you will find all the meetings (so far only virtual) that we organize to provide you with a wide range of information on the use of essential oils and doTERRA products for what you need. We look forward to seeing you.',
    public: 'Open to the general public',
    exclusive:
      'Intended for the Wonderland Essentials team and their guests not enrolled in doTERRA',
    private: 'For Wonderland Essentials only',
  },
  // single event page
  eventPage: {
    eventTitle: 'Event Title',
    failedToFetch: 'Could not fetch event data.',
    addEventToMyCallendar: 'Add Event to my Calendar',
    joinEvent: 'Join this event',
    eventType: 'Event type',
    eventRecurrence: 'Recurrence',
    addZoomlink: 'Add zoom link',
    eventDescription: 'Description',
    eventOccurences: 'Occurrences',
    organizerAndGuests: 'Organiser and guests',
    deleteEvent: 'Delete this event',
    editEvent: 'Edit event',
    editEventTitle: 'Update event',
    eventLink: 'Event link: ',
    from: 'From',
    to: 'To',
    customMobileButton: 'Button text',
    addToCalendar: 'Add to Calendar',
    customMobileButtonPlaceholder: 'Join with Zoom',
    toastify: {
      eventUpdated: 'Event updated.',
      eventNotUpdated: 'Unable to update event.',
      eventDeleted: 'Event updated.',
      eventNotDeleted: 'Unable to delete event.',
    },
    errors: {
      weekRecurrence: 'You need to pick at least one day.',
      host: 'Event host is required.',
      hostLength: 'Organiser and guests field must be less than 65 characters.',
      description: 'Description is required.',
    },
    breadcrumbs: {
      all: 'All Events',
    },
    deleteModal: {
      title: 'Confirm delete event',
      confirm: 'Yes, delete it',
      body: {
        text: 'You are about to completely delete this event and its information. ',
        secondText:
          'The event will not be visible anymore. This action cannot be undone.',
        areYouSure: 'Are you sure you want to',
        deleteHighlight: 'permanently delete',
        end: 'this event?',
      },
    },
    confirmModal: {
      title: 'Confirm modifications',
      confirm: 'I understand',
      body: {
        text: 'Updating an active event might have an impact on some users.',
        secondText: 'Are you sure you want to do this ?',
      },
    },
    customRecurrence: {
      title: 'Custom Recurrence',
      confirm: 'Done',
      repeat: 'Repeat every',
      repeatOn: 'Repeat on',
      includeWeekends: 'Include weekends',
      repeatInterval: 'Repeat interval',
      daily: {
        dailyUntil: 'Daily, until ',
        dailyAfter: 'Daily, ',
        dailyTimes: ' times',
        dailyNever: 'Daily',
      },
      monthly: {
        monthlyUntil: 'Monthly, until ',
        monthlyAfter: 'Monthly, ',
        monthlyTimes: ' times',
        monthlyNever: 'Monthly',
        monthlyEveryDay: 'Monthly on ',
        monthlyEvery: 'Monthly every ',
        first: ' first ',
        second: ' second ',
        third: ' third ',
        fourth: ' fourth ',
        monthlyEnd: ' of the month ',
        monday: ' monday ',
        tuesday: ' tuesday ',
        wednesday: ' wednesday ',
        thursday: ' thursday ',
        friday: ' friday ',
        saturday: ' saturday ',
        sunday: ' sunday ',
      },
      yearly: {
        yearlyUntil: 'Yearly, until ',
        yearlyAfter: 'Yearly, ',
        yearlyTimes: ' times',
        yearlyNever: 'Yearly',
      },
      ends: 'Ends',
      never: 'Never',
      on: 'On',
      after: 'After',
      event: ' event',
    },
  },
  // protocols
  protocolsPage: {
    pageTitle: 'Protocols',
    addProtocol: '+ Add Protocol',
    favoriteText: 'Show only favorites',
    showing: {
      pre: 'Showing',
      post: 'Protocol',
      plural: 'Protocols',
      none: 'No protocols',
    },
    categories: {
      all: 'All',
      physical: 'Physical',
      emotional: 'Emotional',
    },
    filters: 'Filters',
    protocolAdded: 'Protocols successfully added.',
    protocolNotSaved: 'Could not save protocol.',
    addProtocolTitle: 'Create New Protocol',
    addProtocolButton: 'Create protocol',
    selectProtocolLanguage: 'Select protocol language',
    selectLanguage: 'Protocol language',
    title: 'Protocol title',
    altName: 'Alternative Name',
    category: 'Protocol category',
    description: 'Description',
    ingredients: 'Ingredients',
    benefits: 'Benefits',
    cautions: 'Cautions',
    translatedBy: 'Credits',
    imageRequired: 'Please select a protocol image.',
  },

  // single protocol page
  protocolPage: {
    failedToFetch: 'Could not fetch protocol data.',
    breadcrumbs: {
      all: 'All Protocols',
    },
    selectLanguage: 'Select protocol language',
    faledToFetchSimilar: 'Could not get similar protocols.',
    updatedProtocol: 'Protocol updated successfully.',
    duplicate: 'Another protocol with that name exists.',
    deletedProtocol: 'Protocol deleted successfully.',
    updateError: 'Unable to update protocol',
    deleteError: 'Unable to delete protocol',
    updated: 'Protocol updated successfully.',
    failedUpdate: 'Could not update protocol details.',
    editProtocol: 'Edit protocol',
    addProtocolTitle: 'Create New Protocol',
    updateProtocol: 'Update protocol',
    deleteProtocol: 'Delete protocol',
    title: 'Protocol title',
    altName: 'Alternative Name',
    physical: 'Physical',
    emotional: 'Emotional',
    altSearchTerms: 'Alternative Search Terms',
    placeholderSearchTerms: 'Add search terms',
    topicalRecommendation: 'Topical Recommendation',
    aromaticalRecommendation: 'Aromatical Recommendation',
    internalRecommendation: 'Internal Recommendation',
    additionalRecommendations: 'ADDITIONAL RECOMMENDATIONS',
    protocolFieldRequired: 'Description is required.',
    protocolDisclamer:
      '*This information is a compilation of suggestions made by those who have used essential oils and do not intend to substitute the advice of a professional doctor. It is an informative document from personal experience and should be treated as such. The recommended products and methods are not intended to diagnose, treat, cure or prevent any disease, nor are they intended to replace appropriate medical care.',
    physicalTopicalRecommendation: 'Physical Topical Recommendation',
    physicalAromaticalRecommendation: 'Physical Aromatical Recommendation',
    physicalInternalRecommendation: 'Physical Internal Recommendation',
    physicalAdditionalRecommendation: 'Physical Additional Recommendations',
    addPhysicalProtocol: 'Add Physical Protocol',
    emotionalTopicalRecommendation: 'Emotional Topical Recommendation',
    emotionalAromaticalRecommendation: 'Emotional Aromatical Recommendation',
    emotionalInternalRecommendation: 'Emotional Internal Recommendation',
    emotionalAdditionalRecommendations: 'Emotional Additional Recommendations',
    addEmotionalProtocol: 'Add Emotional Protocol',
    noProtocolType: 'You have to choose at least one protocol type.',
    deleteModal: {
      title: 'Confirm Delete',
      confirm: 'Yes, Delete it',
      body: {
        top: 'You are about to completely delete the protocol and its information. This action cannot be undone.',
        areYouSure: 'Are you sure you want to',
        deleteHighlight: 'permanently delete',
        end: 'this protocol?',
      },
    },
    zonesDialogButton: 'Application Zones',
    zonesModal: {
      title: 'Application Zones',
      confirm: 'Save',
      solesOfFeet: 'Soles of feet',
      neck: 'Neck',
      forhead: 'Forhead',
      armpits: 'Armpits',
      solarPlexus: 'Solar Plexus',
      heart: 'Heart',
      sacrum: 'Sacrum',
      lombarRegion: 'Lombar region',
      sinuses: 'Sinuses',
      underTheNose: 'Under the nose',
      chest: 'Chest',
      backOfNeck: 'Back of neck',
      spine: 'Spine',
      diaphragm: 'Diaphragm',
      back: 'Back',
      topOfHead: 'Top of head',
      aroundBellyButton: 'Around belly button',
      lowerAbdomen: 'Lower abdomen',
      insideWristCreses: 'Inside wrist creses',
      temples: 'Temples',
      baseOfTheSkull: 'Base of the skull',
      behindTheEars: 'Behind the ears/mastoid',
      alongTheArms: 'Along the arms',
      abdomen: 'Abdomen',
      shoulders: 'Shoulders',
      kidneys: 'Kidneys',
      midlineOfTheBelly: 'Midline of the belly',
      insideElbowCreases: 'Inside elbow creases',
      outerLineOfTheJaw: 'Outer line of the jaw',
      sternBone: 'Stern bone',
      outsideWristCreases: 'Outside wrist creases',
      innerThighArea: 'Inner thigh area',
      theBaseOfTheNeck: 'The base of the neck',
      tailBone: 'Tailbone',
      feet: 'Feet',
      behindTheKnees: 'Behind the knees',
      liverArea: 'Liver area',
    },
    confirmModal: {
      title: 'Confirm modifications',
      confirm: 'I understand',
      body: {
        text: 'Updating an active protocol might have an impact on some users. Are you sure you want to do this?',
      },
    },
  },
  // 404 not found page
  notFoundPage: {
    notFound: 'Page not found',
    title: 'Oops. Looks like something broke.',
    content: "We hope it's not an essential oil bottle ...",
    button: "Let's go home",
  },
  // contact page
  contactPage: {
    title: 'Let’s get in touch',
    text: 'Fill in the form. It’s super easy.',
    content: 'You can also reach us by email at ',
    website: 'support@wonderlandessentials.com',
    contactEmail: 'mailto:support@wonderlandessentials.com',
    button: 'Send Message',
    typeEmail: 'Email Address',
    textAreaLabel: 'Briefly tell us how can we help you',
    messageSent: 'Your message was sent.',
  },
  // request error messages
  errors: {
    generic: {
      serverError: 'Server error.',
      sessionExpired: 'Session expired. Log in again.',
      notAllowed: 'You do not have access to view this page',
    },
    auth: {
      login: {
        notFound: 'User doesn’t exist',
        wrongPass: 'Incorrect password.',
        generic: 'Something went wrong. Please try again or contact us.',
      },
    },
    products: {
      badRequest: 'Bad request.',
      notFound: 'Product not found.',
    },
    protocols: {
      badRequest: 'Bad request.',
      notFound: 'Protocol not found.',
    },
    events: {
      badRequest: 'Bad request.',
      notFound: 'Event not found.',
    },
    users: {
      badRequest: 'Bad request.',
      notFound: 'User not found.',
    },
  },
  dashboard: {
    title: 'Dashboard',
    statistics: 'Statistics',
    blendOfTheMonth: 'Blend of the month',
    products: 'doTERRA Products',
    protocols: 'Essential Oils Protocols',
    languages: 'Languages',
    readMore: 'Read more',
    blendAdd: '+ Add',
    blendEdit: 'Edit blend',
    allBlends: 'All blends',
    noBlends: 'No blends',
    editBlend: 'Select Blend of the Month',
    monthlyOffer: 'Monthly offers',
    blendDisclamer:
      '* This information is a compilation of suggestions made by those who have used essential oils and do not intend to substitute the advice of a professional doctor. It is an informative document from personal experience and should be treated as such. The recommended products and methods are not intended to diagnose, treat, cure or prevent any disease, nor are they intended to replace appropriate medical care.',
    applicationZones: 'Application zones',
    frequency: 'Frequency',
    duration: 'Duration',
    modal: {
      new: 'New monthly offer',
      editOffer: 'Edit monthly offer',
      addBlend: 'Add new blend',
      editBlend: 'Select Blend of the Month',
      deleteBlend: 'Delete Blend',
      selectLanguage: 'Blend Language',
      selectBlend: 'Select blend',
      title: 'Title',
      url: 'URL',
      ok: 'Save',
      blendTitle: 'Blend title',
      blendDescription: 'Description',
    },
    deleteModal: {
      title: 'Confirm Delete',
      confirm: 'Yes, Delete it',
      body: {
        top: 'You are about to completely delete the blend and its information. This action cannot be undone.',
        areYouSure: 'Are you sure you want to',
        deleteHighlight: 'permanently delete',
        end: 'this blend?',
      },
    },
  },
  editor: {
    generic: {
      add: 'Add',
      cancel: 'Cancel',
    },
    link: {
      linkTitle: 'Title',
      linkTarget: 'Link',
      linkTargetOption: 'Open link in new windows',
      link: 'New link',
      unlink: 'Remove link',
    },
  },
  // eduCare page

  eduCarePage: {
    pageTitle: 'eduCare',
    addNewArticle: '+ Add new',
    featured: 'Featured',
    other: 'Other',
    search: 'Search eduCare',
    addEditPage: {
      title: 'New Article',
      editTitle: 'Edit Article',
      other: 'Other articles',
      editArticle: 'Edit this article',
      success: 'Article successfully modified.',
      fail: "Article couldn't be modified.",
    },
    toasts: {
      success: 'New article saved',
      fail: 'Could not save article. Please try again.',
    },
    buttons: {
      save: 'Save',
      cancel: 'Cancel',
    },

    title: 'Article title',
    author: 'Select Author',
    checkboxArticle: 'Featured article',
    deleteArticle: {
      title: 'Confirm delete article',
      confirm: 'Are you sure you want to delete this article?',
      buttonDelete: 'Delete article',
    },
    toastify: {
      articleNotDeleted: 'Could not delete article. Please try again.',
      articleDeleted: 'Article deleted succesfully.',
    },
  },
};
